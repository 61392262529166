.homepage {
  overflow-x: hidden;

  .faded {
    opacity: 0.5;
  }

  .me-nav {
    display: flex;
    justify-content: space-between;

    &--left {
      display: flex;
      flex-wrap: wrap;

      .christmas {
        opacity: 0;
      }

      @include for-tablet-portrait-up {
        flex-wrap: nowrap;

        .christmas {
          margin-top: 0;
          margin-left: 50px;
        }
      }
    }

    &list {
      display: flex;

      &__item {
        margin-left: 20px;
      }

      &__link {
        text-decoration: none;
        color: $white;
        font-weight: normal;

        &:after {
          content: '';

          display: block;
          position: relative;

          height: 2px;
          width: 0;

          transform: translateY(5px);

          background-color: $white;
          transition: 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
        }

        &:hover {
          // border-bottom: 2px solid $white;
          &:after {
            width: 100%;
          }
        }
      }
    }
  }

  .me-header {
    padding-top: 60px;

    text-align: center;

    @include for-tablet-portrait-up {
      padding-top: 100px;
    }

    animation: fadeup 0.6s ease-in-out;
  }

  @keyframes fadeup {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .me-maintitle {
    font-family: $font-family-title;
    font-size: 25px;
    line-height: 35px;
    z-index: 600;

    @include for-tablet-portrait-up {
      font-size: 50px;
      line-height: 60px;
    }

    @include for-desktop-up {
      font-size: 3.5vw;
      line-height: 125%;
    }

    p {
      display: inline;
      margin: 0;

      @include for-tablet-portrait-up {
        display: block;
      }
    }

    .me-myself {
      transition: 0.3s;
      z-index: 999;

      position: relative;
      z-index: 999;

      &:hover {
        cursor: grab;
        color: transparent;
        -webkit-text-stroke: 2px $white;
      }
    }

    .digital-creative,
    .me-adjective {
      position: relative;
      z-index: 10;

      &:before {
        content: '';

        position: absolute;
        top: 30%;
        left: 2%;

        width: 95%;
        height: 60%;

        background-color: #c6d8ff;
        z-index: -1;
        transform: rotate(-0.5deg);
        transition: 0.2s ease-in-out;
      }
    }
  }

  .me-image {
    position: absolute;
    // display: inline-block;

    top: 0;
    left: 0;

    display: none;
    height: 0;

    overflow: hidden;
    z-index: 500;

    &--active {
      @include for-tablet-landscape-up {
        display: block;
        height: 100%;

        animation: grow 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      }
    }
  }

  @keyframes grow {
    0% {
      height: 0;
    }

    50% {
      height: 40%;
    }

    100% {
      height: 100%;
    }
  }

  .me-wavinghand {
    display: inline-block;
    position: relative;

    width: 30px;
    height: 30px;

    background-image: url('https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/apple/279/waving-hand_1f44b.png');
    background-size: contain;
    background-repeat: no-repeat;

    transform-origin: 70% 80%;

    &:hover {
      animation: wave-animation 1.5s ease-in-out;
    }

    @include for-tablet-portrait-up {
      width: 60px;
      height: 60px;
    }
  }

  @keyframes wave-animation {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(14deg);
    } /* The following five values can be played with to make the waving more or less extreme */
    20% {
      transform: rotate(-8deg);
    }
    30% {
      transform: rotate(14deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(0deg);
    } /* Reset for the last half to pause */
    100% {
      transform: rotate(0deg);
    }
  }

  .me-baseline {
    @include body;

    margin: 30px auto;
    max-width: 55ch;
  }

  .me-button {
    margin-top: 80px;
    background-color: $accent;
    color: $bg-light;
    border-color: $accent;

    &:hover {
      cursor: pointer;
      background-color: $white;
      color: $brand;
      border-color: $brand;
    }
  }

  .me-projects--left {
    margin-top: 100px;

    animation: fadeup 0.6s ease-in-out;

    @include for-tablet-portrait-up {
      margin-top: 250px;
      padding-right: 45px;
    }

    .me-h2 {
      font-size: 25px;

      @include for-tablet-portrait-up {
        font-size: 50px;
        line-height: 60px;
      }

      @include for-desktop-up {
        font-size: 2.7vw;
        line-height: 125%;
      }
    }

    p {
      @include body;
      margin-bottom: 30px;

      @include for-tablet-portrait-up {
        margin-bottom: 50px;
      }
    }

    strong {
      font-weight: bold;
    }
  }

  .me-projects--right {
    margin-top: 80px;

    display: flex;
    flex-wrap: wrap;

    animation: fadeup 0.6s ease-in-out;

    @include for-tablet-portrait-up {
      margin-top: 180px;
      padding-left: 45px;
    }

    .me-project {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      width: 100%;
      aspect-ratio: 4 / 3;
      max-height: 380px;
      min-height: 220px;
      border: 2px solid $brand;
      margin: -2px 0 0 -2px;

      @include for-tablet-portrait-up {
        width: calc(100% / 2 - 2px);
      }

      &__check {
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        background-color: $brand;
        color: $bg-light;
        padding: 10px 8px;
        font-size: 15px;
        opacity: 1;
        transition: 0.2s ease-in-out;

        @include for-tablet-portrait-up {
          opacity: 0;
        }
      }

      &__logo {
        max-height: 70px;
        max-width: 150px;
        opacity: 1;

        position: absolute;
        transition: opacity 0.3s;
        filter: brightness(0) invert(0);
      }

      &__image {
        max-height: 260px;
        opacity: 0;

        position: absolute;
        transition: opacity 0.3s;

        display: none;

        @include for-tablet-landscape-up {
          display: initial;
        }
      }

      @include for-desktop-up {
        &:hover {
          .me-project__check {
            opacity: 1;
          }

          .me-project__logo {
            opacity: 0;
          }

          .me-project__image {
            opacity: 1;
            z-index: 999;
          }
        }
      }
    }
  }

  .me-contact {
    margin-top: 100px;
    background-color: $black;
    padding: 30px;
    padding-bottom: 50px;

    color: $black;

    @include for-tablet-portrait-up {
      margin-top: 200px;
      padding: 60px;
      padding-bottom: 150px;
    }

    @include for-desktop-up {
      margin-top: 300px;
      padding: 100px;
      padding-bottom: 200px;
    }

    .me-h2 {
      letter-spacing: 1px;
      max-width: 17ch;

      font-weight: 400;

      color: $white;

      font-size: 20px;
      line-height: 120%;

      @include for-tablet-portrait-up {
        font-size: 50px;
        line-height: 60px;
      }

      @include for-desktop-up {
        font-size: 2.7vw;
        line-height: 125%;
      }

      @include for-tablet-portrait-up {
        font-size: 5rem;
        line-height: 70px;
      }

      @include for-desktop-up {
        font-size: 5rem;
        line-height: 125%;
      }
    }

    &__baseline {
      @include body;
      color: $white;
      max-width: 55ch;
    }

    .form {
      margin-top: 80px;
      max-width: 800px;

      z-index: 999;

      @include for-desktop-up {
        margin-top: 180px;
        margin-left: 200px;
        max-width: 800px;
      }

      .notice {
        margin-bottom: 20px;
      }
    }

    .inputLabel {
      display: inline-block;
      font-weight: bold;
      width: 100%;

      z-index: 999;

      .label {
        display: inline-block;
        margin-bottom: 15px;
        color: $white;
      }
    }

    .inputfield {
      color: $white;
      border-bottom: 2px solid $white;
      margin-bottom: 50px;

      &:hover,
      &:focus {
        border-color: $white;
        background-color: rgba(251, 250, 245, 0.05);
      }
    }

    .me-button {
      position: relative;
      border-color: $white;
      color: $white;
      width: 100%;
      margin-top: 50px;
      padding: 20px;
      font-weight: bold;

      background-color: transparent;

      transition: 0.2s;

      &:hover {
        background-color: $white;
        color: $brand;
      }
    }

    .form__bottomAdd {
      text-align: center;
      padding: 15px 0;
      line-height: 30px;
      color: $white;

      &--link {
        color: $white;
        font-weight: bold;
        text-decoration: none;
        border-bottom: 2px solid $white;
        margin-right: 10px;
      }
    }
  }

  .me-h2 {
    font-family: $font-family-title;
    font-size: 35px;

    @include for-tablet-portrait-up {
      font-size: 3.5vw;
      line-height: 125%;
    }

    &--light {
      color: $bg-light;
    }
  }

  .me-footer {
    margin-top: 60px;

    img {
      filter: brightness(0) invert(0);
    }
  }

  // 3d shapes =========================================================================
  .me-ball {
    display: block;
    position: absolute;

    top: 70px;
    right: -100px;

    width: 220px;
    height: 220px;

    z-index: -1;
    opacity: 1;

    background-image: url('../images/me-ball.png');
    background-size: contain;
    background-repeat: no-repeat;

    @include for-tablet-landscape-up {
      top: 160px;
      right: 300px;
    }
  }

  .me-cube {
    display: block;
    position: absolute;

    bottom: -190px;
    left: -50px;

    width: 220px;
    height: 220px;

    z-index: -1;
    opacity: 1;

    background-image: url('../images/me-cube.png');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .me-torus {
    display: none;
    position: absolute;

    bottom: 300px;
    left: -75px;

    width: 250px;
    height: 300px;

    background-image: url('../images/me-torus.png');
    background-size: contain;
    background-repeat: no-repeat;

    @include for-tablet-landscape-up {
      display: block;
    }
  }
}
