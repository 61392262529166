// general imports
@import './general/reset';
@import './general/vars';

// mixins imports
@import './mixins/screens';
@import './mixins/uiElements';

// ui imports
@import './ui/containers';
@import './ui/default';

// component imports

// page imports
@import './pages/home';

// general css
html {
  font-size: 62.5%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  width: 100vw;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: 300;
  overflow-x: hidden;
  background-color: $bg-light;
  color: $brand;

  animation: fadein 0.5s linear;
}

::selection {
  color: $bg-light;
  background: $brand;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

main {
  position: relative;
}

h2 {
  @include h2;
}

// .pagecontent {
//   opacity: 1;
//   transform: translateY(0);

//   transition: 0.3s ease-in-out;
// }

// .pagecontent.hidebody {
//   opacity: 0;
//   transform: translateY(150px);
// }

// @keyframes fadedown {
//   0% {
//     opacity: 1;
//     transform: translateY(0);
//   }

//   100% {
//     opacity: 0;
//     transform: translateY(150px);
//     display: none;
//   }
// }

.me-pagetitle {
  position: relative;

  div {
    display: inline-block;
    overflow: hidden;

    max-height: 180px;

    margin-bottom: -30px;

    @include for-tablet-landscape-up {
      height: 150px;
    }

    @include for-desktop-up {
      height: 230px;
    }

    & span:first-child {
      display: inline-block;
      transform: translateY(0);
      opacity: 1;

      animation: comeUp 0.7s;
    }

    & span:last-child {
      display: inline-block;
      transform: translateY(0);
      opacity: 1;

      animation: comeUp2 0.9s;
    }

    @keyframes comeUp {
      0% {
        transform: translateY(250px);
        opacity: 1;
      }

      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

/* 3D RELATED */

// body { width: 100%;
//   height: 100%;
//   margin: 0;
//   position: fixed;
//   background: rgba(25,25,25, 1) }

canvas {
  width: 100%;
  height: 100%;
  outline: none;
}

#container {
  position: absolute;
  width: 100%;
  height: 100%;
  position: relative;
}
