// all common ui elements (titles, body, buttons, links,...)
@mixin pagetitle {
  font-size: 9vw;
  font-weight: 500;
}

@mixin h2 {
  font-size: 35px;
  font-weight: $bold;
  line-height: 45px;
  margin-bottom: $spacing;

  @include for-tablet-portrait-up {
    font-size: 45px;
    line-height: 60px;
  }
}

@mixin h3 {
  font-size: 20px;
  font-weight: 500;

  @include for-tablet-portrait-up {
    font-size: 25px;
    margin-bottom: 20px;
  }
}

@mixin body {
  font-size: 20px;
  line-height: 33px;
  color: $text-color;
  font-family: $body-font;
}

@mixin link {
  color: $black;
  text-decoration: none;
  border-bottom: 2px solid $brand;
  transition: $animation;

  &:hover {
    color: $brand;
  }
}

@mixin small {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: $bold;
  color: $brand;
}

.wrapper {
  padding: 0 20px;
  position: relative;

  @include for-tablet-portrait-up {
    padding: 0 $spacing2x;

    max-width: 1300px;
    margin: 0 auto;
  }
}

@mixin button {
  display: block;
  background-color: transparent;
  border: 2px solid $black;
  padding: 12px 20px;
  color: $black;
  text-decoration: none;
  text-transform: lowercase;
  font-size: 20px;

  width: auto;

  transition: $animation;

  &:hover {
    cursor: pointer;
    background-color: transparent;
    color: $brand;
    border-color: $brand;
  }
}

@mixin link {
  text-decoration: none;
  color: $brand;
  font-size: 22px;

  transition: 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: $black;
  }
}

@mixin styledInput {
  font-family: $font-family;
  border: none;
  border-bottom: 1px solid $black;
  font-size: 20px;
  padding: 6px;
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  margin-bottom: 30px;

  transition: $animation;

  &:focus,
  &:hover {
    outline: none;
    border-color: $brand;
  }
}

// classes linked to mixins

.me-pagetitle {
  @include pagetitle;
}

.me-link {
  @include link;
}

.me-body {
  @include body;
}

.me-h3 {
  @include h3;
}

input,
select,
textarea {
  @include styledInput;
}

.me-button {
  @include button;
  display: inline-block;
}
