// colors
$bg-light: #fffff2;
$brand: #191919;

$black: #191919;
// $white: #FBFAF5;
$white: #fffff2;

$accent: #191919;

// typo
// $font-family: 'Hind Madurai', sans-serif;
$font-family: 'Manrope', sans-serif;
$font-family-title: 'Dela Gothic One', cursive;
$font-size: 2rem;
$bold: bold;
$text-color: $black;
$body-font: $font-family;

// layout
$max-width: 130rem;
$spacing: 5rem;
$spacing2x: 10rem;
$vertical-spacing: 15rem;

$width-1: calc(1300 * 0.3);

// UI
$animation: 0.2s ease-in-out;
$nav-height: 15rem;
$box-shadow: 2px 2px 25px 0px rgba(0, 0, 0, 0.1);
